.exercise h1 { text-align: center;padding-top: 30px;}


/* =============== CUSTOM FORMS =================== */
/* coloring objects */
.exercise .settings .header { background-color: rgb(153, 134, 109);color:white;border: 1px solid rgb(122, 105, 82); }
.exercise .round .header {background-color: rgb(96, 134, 81);color:white;border:1px solid rgb(52, 95, 52);}

.exercise .settings { background-color: rgb(207, 201, 183); }
.exercise .round { background-color: rgb(208, 221, 200); }


/* align rules for objects */
.exercise .item {border-radius: 10px;margin-bottom: 10px;}
.exercise .item .header { 
    width: 100%;
    padding:20px 20px;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}
.exercise .separate {display: flex;justify-content: space-between;align-items: center;}
.exercise .header .header-extra { font-size: 0.8rem;font-style: italic;font-weight:normal;text-align: left;width: 100%;padding-top:5px; }

.exercise .item .controls {display: grid;padding: 20px;}
.exercise .item .controls.hide {display: none;}
.exercise .item .controls input { margin-bottom: 5px;font-size: 1.1rem; }
.exercise .item .controls span {display: block;font-weight: 600;font-size: 1.1rem;padding-bottom:5px}
.exercise .item .controls .subheader { font-size: 1.2rem;font-weight: 700; }

.exercise .list-control { display: flex; }
.exercise .list-control div {width:100%;max-width: 200px;text-align: center;margin-right: 20px;}
.exercise .item select {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;    
}
/* ============== /CUSTOM FORMS =================== */

/* button layout */
.exercise .button-toolbar { 
    width: 100%; 
    height: 60px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-top: solid 2px;
    border-bottom: solid 2px;
    border-color: black;
}
.exercise .button-toolbar.delete {
    background-color: rgb(238, 79, 79);
    color:white;
    border-color: rgb(66, 19, 19);
}
.exercise .button-toolbar.save {
    background-color: rgb(98, 114, 185);
    color:white;
}
.exercise .button-toolbar.action {
    background-color: rgb(201, 206, 230);
    color:black;
    border-right: 2px solid rgb(147, 147, 163);
}
.exercise .button-toolbar.start {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: solid 2px black;
}
.exercise .button-toolbar.end { 
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: solid 2px black;
}
.exercise .button-toolbar.disabled {
    background-color: rgb(173, 173, 173);
    color:rgb(141, 141, 141);
    cursor: default;
}

.exercise .edit-button {
    background-color: rgb(238, 222, 0);
    border: 2px solid rgb(94, 88, 54);
    color:black;
    font-size: 1rem;
    font-weight: 600;;
    padding: 10px;
    border-radius: 5px;
}

.exercise .back-button { 
    background-color: rgb(99, 99, 99);
    width: fit-content;
    padding:5px 10px;
    border-radius: 3px;
    cursor: pointer;
    color:white;
    font-weight: 600;
    margin-bottom: 10px;
    border: none;
    font-size: 1rem;
}

.exercise .delete-button {
    background-color: rgb(230, 82, 82);
    color:white;
    font-size: 1.1rem;
    font-weight: 600;
    padding:10px;
    border-radius: 5px;
    width: 33.3%;
    border:2px solid rgb(82, 20, 20);
    cursor: pointer;
}


.exercise .form-button { 
    background-color: rgb(160, 160, 160);
    border:1px solid rgb(100, 100, 100);
    color:rgb(71, 71, 71);  
    border-radius: 3px;
    font-size: 1.1rem;
    padding:5px 0;
    font-weight: 600;
    margin-right: 2px;
}
.exercise .form-button.enabled {background-color: rgb(230, 230, 230);border:1px solid rgb(85, 85, 85);}

.exercise .button-field {max-width: 600px;display: flex;}
.exercise .button-field .form-button, .exercise .button-field .button { width: 33.3%;}


.exercise .selections {
    padding:30px 20px;
    display: grid;
}

.exercise .exercise-box {
    display: grid;
    text-align: left;
    padding:30px 30px;
    margin-bottom: 20px;
    border: none;
    color:white;
    cursor: pointer;
    background-color: rgb(104, 145, 92);
}

.exercise .exercise-box .header { font-size: 2rem;font-weight: 600; }
.exercise .exercise-box .text { font-size: 1rem; }
.exercise .exercise-box li { margin-bottom: 5px;}
.exercise .exercise-box li span {font-weight: 600;}

@media screen and (max-width:500px) {
.exercise .selections {padding: 30px 5px;}
.exercise .exercise-box .header { font-size: 1.5rem; }
.exercise .exercise-box .text { font-size: 1rem; }
.exercise .form-button {font-size: 0.8rem;}
}
