.support .notestyle { background-color: rgb(255, 255, 255,0.7);border-radius: 2px;width: fit-content;margin-top:80px;text-align: center; }

.support .developer {
    background-color: rgb(238, 238, 238);
    border:1px solid rgb(119, 119, 119);
    font-size: 0.9rem;
    width: 400px;
    padding: 10px 20px;
    border-radius: 2px;
}
.support .developer h2 {font-size: 1rem;color:rgb(59, 30, 16);}

@media screen and (max-width:1200px) {
    .support .notestyle {margin-left:auto;margin-right: auto;}
}

@media screen and (max-width:500px) {
    .support .notestyle {width: 90%;font-size: small;}
    .support .developer {width: 100%;}
}