/* fixing default border and margin behavior */
* {box-sizing: border-box;border-collapse: collapse;}
ul {list-style: none;padding:0;}
html, body {width: 100%;border:none;margin:0;padding:0;}

/* fixing default headers */
h1, h2, h3 { margin:0;padding:0;font-weight: 700;color:black}
h1.center, h2.center, h3.center {text-align: center;}

/* fix react issue */
body div iframe { height: 0px;background-color: black;position: absolute;left:-200%; }


/*===========================================================
      VERTICAL VIEW 
  ===========================================================*/
  .height-stretch {display: flex;flex-direction: column;min-height: 100vh;}
  .height-stretch .height-grow { flex-grow: 1;}



/*===========================================================
      PAGE SETTINGS
  ===========================================================*/
body { background-color: rgb(237, 238, 224) }

.navbar-space { height: 70px; }

.page-wrapper { margin:30px auto; }
.page-wrapper.white { background-color: white;}

.width-fit {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.edit-icon {
  height: 30px;width: 30px;
  border: none;
  background-color: rgb(255, 254, 239);
  padding:3px;
  border-radius: 7px;
  background-size:contain;
  background-repeat: no-repeat;
}
.edit-icon:hover {background-color: rgb(207, 204, 164)}


/*===========================================================
      TEXT-CONTENT
  ===========================================================*/
.text-content {
  width: 100%;
}
.text-content.notestyle {background-color: white;padding:30px 0;}
.text-content::after {  content: "";clear: both;display: table;}

.text-content .text { padding:0 20px; }

.text-content img {
  max-width: 45%;
  max-height: 400px;
  margin: 0 20px;
  margin-bottom: 5px;
  pointer-events: none;
}
.text-content img.right {float:right; }
.text-content img.left {float:left; }




/*===========================================================
    BACKGROUND AND IMAGE LAYOUTS
  ===========================================================*/
.background-image-fixed {
  z-index: -1;
  object-position:center;
  object-fit: cover;
  position: fixed !important;
  top:0;
  left:0;
  height: 100vh;
  width: 100%;
  transform: translate3d(0,0,0); 
  filter:brightness(120%);
}


@keyframes zoom1 {0% {transform:scale(1)}50% {transform:scale(1.1)} 100% {transform:scale(1)}}
@keyframes move1 {
  0% {transform: translate(-30px)scale(1.1)}
  25% {transform: translate(0px, 10px)scale(1.15)}
  50% {transform: translate(30px)scale(1.1)}
  75% {transform: translate(0px,-10px)scale(1.15)}
  100% {transform: translate(-30px)scale(1.1)}
}
.welcome-layout1 {display: flex;align-items: center;justify-content: center;}
.welcome-layout1.top { width: 100%;height:70vh;background-size:cover;background-position: center;overflow: hidden;position: relative; }
.welcome-layout1.top .animation {background-size:cover;background-position: center; width: 100%;height: 100%;animation:zoom1 30s ease infinite;} 
.welcome-layout1.bottom { width: 100%; height:30vh;}


.centralize { display: flex; justify-content: center;align-items: center; }

/*===========================================================
      COMPACT VIEW
  ===========================================================*/
@media screen and (max-width:1200px) {
  .page-wrapper {margin:0}
}

/*===========================================================
      MOBILE VIEW
  ===========================================================*/
@media screen and (max-width:500px) {
  h1 { font-size: 1.5rem; }
  h2 { font-size: 1.3rem; }
  h3 { font-size: 1.1rem; }

  .text-content.notestyle {padding-top:0;}
  .text-content .text { padding:20px 10px }
  .text-content img { max-width: none;max-height: none;width: 100%;}
  .text-content img.mobile { height: 200px;object-fit: cover;object-position: center; }
  .text-content img.right, .text-content img.left { float:none;margin:0;}

  .welcome-layout1.top { height: 100vh;}
  .welcome-layout1.bottom { position: absolute;top:55vh;height: 45vh;}
}
 