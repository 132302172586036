
.login .content {
    margin-left:auto;
    margin-right: auto;
    max-width: 300px;
    background-color: white;
    padding: 30px;
}

.login .space100 {height: 100px;}

.login a { cursor: pointer;}

@media screen and (max-width:500px) {
   .login .content {max-width: none;}
   .login .space100 {height: 30px}
   .login { background-color: white;}
}
