.home {background-color: black;}

.home h1,.home h2,.home h3 { color: white; }

.home .bottom {
    background-color: rgb(12, 17, 24);
    color: white;
}

.home .boxes {
    display: flex;
    justify-content: space-around;
    height: 100%;
}

.home .box {
    text-align: center;
    padding: 30px 0;
    text-decoration: none;
    color:white
}
.home .box img { object-fit: scale-down;max-height: 50%;max-width: 50%;}

@media screen and (max-width:800px) {
    .home .box {font-size: 0.8rem;}    
}
@media screen and (max-width:500px) {
    .home .bottom {background-color: rgb(12, 17, 24,0.8);overflow-y: auto;}
    .home .boxes { flex-wrap: wrap; align-items: center;}
    .home .box {padding: 0;}
    .home .box img { width: 40px;}
}

