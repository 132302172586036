.yoga2  { 
    background-color: rgb(43, 43, 43); 
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.yoga2 .test.show {display: block;}
.yoga2 .test.hide {display: none;}


/* background */
@keyframes imgAnim {
    0%{transform: scale(1)}
    50%{transform: scale(1.1)}
    100%{transform: scale(1);}
}
.yoga2 .bg-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter:brightness(50%);
    animation: imgAnim 20s ease infinite;
}
.yoga2 .bg-img.hide {opacity: 0;}
.yoga2 .bg-img.show { opacity: 1;transition: 1s; }



/* main grid and frames */
.yoga2 .grid {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0px;
    left:0px;
}
.yoga2 .frame {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}
.yoga2 .frame.shaded { background-color: rgb(0, 0, 0,0.3);}

/* gauge */
.yoga2 .gauge {
    display: grid;
    gap: 10px;
    justify-content: center;
}
.yoga2 .gauge .info h2 { color: rgb(226, 226, 226);text-align: center;font-size: 2rem; }

.yoga2 .buttons {padding-top: 20px;text-align: center;}
.yoga2 .buttons button { width:100px;height: 80px; border-radius: 15px;margin:0 5px;background-color: rgb(218, 218, 218,0.7);border:1px solid rgb(71, 71, 71); }
.yoga2 .buttons button:hover {background-color: white;}

.yoga2 .video { 
    background-color: black;
    padding:10px;
    border-radius: 10px;
    position: relative;
    margin-left:auto;margin-right: auto;
}
.yoga2 .overlay {
    width: 100%;
    height: 100%;
    background-color: black;
    color:rgb(226, 226, 226);
    font-size: 3.5rem;
    font-weight: 700;
    border-radius: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0px;
    left:0px;
}
.yoga2 .overlay.enable { opacity:1}
.yoga2 .overlay.disable { opacity: 0;transition: 1s ease-in; }

/* exercise overview */
.yoga2 .overview { color: rgb(235, 255, 230); }
.yoga2 .overview ul { font-size: 2.5rem;margin-bottom: 0;}
.yoga2 .overview ul li { padding: 5px 10px; }
.yoga2 .overview ul li.active { background-color: rgba(159, 159, 187, 0.3);border-radius: 10px; }
.yoga2 .overview p { font-size: 1.5rem;text-align: center;font-style: italic; }


/* COMPACT VIEW */
@media screen and (max-width:1000px) {
    /* overlay */
    .yoga2 .overlay {font-size: 2.5rem;}
    /* exercise overview */
    .yoga2 .overview ul { font-size: 1.8rem;}
    .yoga2 .overview p { font-size: 1rem;}

}

/* MOBILE VIEW */
@media screen and (max-width:750px) {
    /* BACKGROUND */
    .yoga2 .bg-img {width: auto;height:100%}
    /* GRID */
    .yoga2 .grid { grid-template-columns: none; grid-template-rows: 2fr 1.5fr; }

    .yoga2 .gauge .info h2 { font-size: 1.5rem;}

    /* exercise overview */
    .yoga2 .frame.shaded {overflow-y: auto;}
    .yoga2 .overview ul { font-size: 1.3rem;}
    .yoga2 .overview p { font-size: 1rem;}
}

